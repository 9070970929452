<template>
  <div>
    <heade currentrStr=""></heade>
    <div class='common-width' style='padding:10px;'>
      <a-breadcrumb style="margin-top:20px;">
        <a-breadcrumb-item><a href="javascript:;" @click='to("./index")'>上一页</a></a-breadcrumb-item>
        <a-breadcrumb-item>{{title}} - 全国总决赛获奖结果</a-breadcrumb-item>

      </a-breadcrumb>
      <img src="../images/2022compiler.png" v-if='type == "bianyi"' width=90%/>
      <div  v-if='type == "xitong"'>
        <h1  style='margin-top:30px;'>2022 年全国大学生计算机系统能力大赛操作系统设计赛</h1>
        <h2>全国总决赛获奖结果</h2>
            <!-- <p><a href="javascript:;" class="look-a" @click="to('./index?tab=5')"
                >点击查看开源代码 》</a
              ></p> -->
          <img src="../images/js1.png" width=90%>
          <img src="../images/js2.png" width=90%>
          <img src="../images/js3.png" width=90%>
          <img src="../images/js4.png" width=90%>
          <img src="../images/js5.png" width=90%>
          <p class="text-part" style="line-height: 2; text-indent: 2em;margin-top: 10px;">以上名单为公示版，如有异议，请各参赛队于2022年8月25日前发邮件至cscc-os@hzbook.com进行咨询或申诉，组委会将依据竞赛章程和技术方案进行复核，待公示期结束后发布最终版。</p>
        <h2>优秀指导教师</h2>
        <img src="../images/yxzdjs.png" width=90%>
        <h2>优秀企业导师</h2>
        <img src="../images/yxqyds.png" width=90%>
        <h2>最佳技术支持奖</h2>
        <img src="../images/zjjszc.png" width=90%>
        <h2>特别贡献奖</h2>
        <img src="../images/tbgx.png" width=90%>
        <h2>决赛入围名单暨优胜奖获得者  </h2>
        <!-- <p><a href="javascript:;" class="look-a" @click="to('./index?tab=4')"
                >点击查看开源代码 》</a
              ></p> -->
              <p>【以学校名称拼音排序】</p>
                 <div  v-if= 'fontType'>
              <h3>👇 操作系统功能赛 👇</h3>
              <img src="../images/gn.png" width=90%>
           </div>
              <div v-if= '!fontType'>
                   <h3>👇 操作系统内核赛 👇</h3>
              <img src="../images/nh.png" width=90%>
              </div>
        
      </div>

       
    </div>
    <div>
    <a-back-top />
  </div>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
export default {
  components: {
    heade,
  },
  data(){
    return {
      type:null,
      title:window.localStorage.getItem("anjing-parent"),
      fontType:null
    }
  },
  mounted(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
     if (window.localStorage.getItem("anjing-parent") == '编译系统大赛') {
      // 编辑赛
      this.type = "bianyi";
    } else {
      console.log(window.localStorage.getItem("anjing-stitleNew"))
      this.type = "xitong";
      this.fontType =(window.localStorage.getItem("anjing-stitleNew")).indexOf('功能挑战赛')!=-1?true:false
    }
  },
methods:{
      to(url) {
      this.$router.push({ path: url });
    },
}
};
</script>
<style scoped>
/deep/ .ant-breadcrumb{
        text-align: left;



}
.look-a {
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
h2{
  margin:30px 0;
}
</style>